.table {
    border-radius: 4px;
    border: none;
    min-height: 500px;
}

.table:global(.MuiDataGrid-root .MuiDataGrid-main) {
    border-radius: 4px;
}

.table:global(.MuiDataGrid-root .MuiDataGrid-cell) {
    padding: 0 4px;
}

.table:global(.MuiDataGrid-root .MuiDataGrid-cell):focus-within {
    outline: none;
}

.table:global(.MuiDataGrid-root .MuiDataGrid-columnHeaders) {
    background: var(--white);
    color: var(--text-color-primary);
    border: none;
}

.table:global(.MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnSeparator) {
    display: none;
}

.table:global(.MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader) {
    padding: 0 10px 0 0;
}

.paginationContainer {
    width: 100%;
}

.pagination:global(.MuiPagination-root .MuiButtonBase-root .MuiSvgIcon-root) {
    fill: var(--button-text-color-secondary);
}

.pageButton {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0 6px;
    margin: 0 3px;
    color: var(--button-text-color-secondary);
}

.pageButton:hover {
    background: rgba(0, 0, 0, 0.04);
}

.pageButton:active {
    outline: 2px solid rgba(0, 0, 0, 0.24);
}

.selectedPageButton {
    color: var(--color-primary);
}

.paginationInfoText {
    color: var(--blue-50);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.pageSizeButton {
    display: flex;
    align-items: center;
    gap: 2px;
    border: none;
    background: none;
    cursor: pointer;
}

.paginationInfo {
    gap: 2px;
}
